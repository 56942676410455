<template>
  <div>
    <div
      style="margin-bottom: 10px; display: flex; justify-content: space-between"
    >
      <el-input
        size="mini"
        placeholder="请输入KOL名称或者标题"
        suffix-icon="el-icon-search"
        v-model="search"
        clearable
        @change="handleSearch"
        style="width: 340px"
      >
        <template #prepend>
          <el-select
            size="mini"
            v-model="searchCol"
            @change="handleSearchCol"
            style="width: 110px"
          >
            <el-option
              v-for="item in searchColOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-input>
      <el-button
        size="mini"
        @click="handleExpansion"
        :class="['spandAll', { isExpandClass: !isExpand }]"
      >
        <!-- 全部展开 -->
        {{ !isExpand ? "全部展开" : "全部收起" }}
        <i
          :class="!isExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
        ></i>
      </el-button>
    </div>
    <el-table
      class="report_stored_table_class"
      ref="sortTable"
      v-loading="loading"
      :data="tableData"
      :header-cell-style="headerStyle"
      :cell-style="cellStyle"
      stripe
      :default-sort="{ prop: 'postTime', order: 'descending' }"
      @sort-change="handleSort"
      :expand-row-keys="expandFunc"
      row-key="contentId"
    >
      <el-table-column width="25" type="expand">
        <template #default="props">
          <div style="background-color: rgb(240, 245, 255); border-radius: 5px">
            <div style="margin-bottom: 6px; padding: 5px 10px">
              <!-- <span style="font-size: 16px">用户上传信息</span> -->
              <el-table
                style="width: 100%; margin-top: 10px"
                :data="buildUserUploadInfo(props.row)"
                border
              >
                <el-table-column align="center" label="是否报备" prop="report">
                  <template #default="{ row }">
                    {{ row.report === null ? "--" : row.report }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="推流费用"
                  prop="flowCost"
                >
                  <template #default="{ row }">
                    {{
                      row.flowCost === null
                        ? "--"
                        : "￥" + sliceDecimal(row.flowCost)
                    }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="ROI" prop="roi">
                  <template #default="{ row }">
                    {{ row.roi === null ? "--" : row.roi }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title" width="125" align="left">
        <template #default="{ row, column, $index }">
          <el-tooltip
            placement="top"
            :enterable="false"
            popper-class="report_popper_class"
          >
            <template #content>
              {{
                row.title == null || row.title == "null" || row.title == ""
                  ? row.url
                  : row.title
              }}
            </template>
            <a class="ellipsis a_href" :href="row.url" target="_blank">
              {{
                row.title == null || row.title == "null" || row.title == ""
                  ? row.url
                  : row.title
              }}
            </a>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in colData"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :align="item.align"
        :width="item.width"
        :sortable="item.sortable"
        :fixed="item.fixed"
        :formatter="item.formatter"
      >
        <template #header>
          <div v-if="item.label === '平台'">
            <el-select size="mini" v-model="tablePlatform">
              <el-option
                v-for="item in finSites"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </div>
          <span v-else>{{ item.label }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="费用"
        prop="cost"
        align="center"
        width="100"
        sortable
      >
        <template #default="scope">
          {{ scope.row.cost ? sliceDecimal(scope.row.cost / 100) : "--" }}
        </template>
      </el-table-column>
      <el-table-column
        width="70"
        label="供应商"
        prop="agencyName"
        align="center"
      >
        <template #default="scope">
          {{ scope.row.agencyName ? scope.row.agencyName : "--" }}
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin: 10px 0">
      <el-pagination
        background
        layout="prev, pager, next, total"
        :current-page="page"
        :page-size="pageSize"
        :total="totalSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { h } from 'vue';
import { getStoredList } from "@/api/campaignReport";
import { mapGetters } from "vuex";

export default {
  name: "storedDataComponent",
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      searchCol: "title",
      searchColOptions: [
        { label: "标题", value: "title" },
        { label: "KOL名称", value: "kolName" },
      ],
      search: "",
      sortCol: "postTime",
      sortDirection: "desc",
      colData: [
        // {
        //   label: "标题",
        //   prop: "title",
        //   sortable: false,
        //   width: 125,
        //   align: "left",
        //   formatter: (row, column, cellValue) => {
        //     return (
        //       <el-tooltip
        //         placement="top"
        //         enterable={false}
        //         content={
        //           cellValue == null || cellValue == "null" || cellValue == ""
        //             ? row.url
        //             : cellValue
        //         }
        //         popper-class="report_popper_class"
        //       >
        //         <a class="ellipsis a_href" href={row.url} target="_blank">
        //           {cellValue == null || cellValue == "null" || cellValue == ""
        //             ? row.url
        //             : cellValue}
        //         </a>
        //       </el-tooltip>
        //     );
        //   },
        // },
        {
          label: "发布日期",
          prop: "postTime",
          sortable: "custom",
          width: 104,
          align: "center",
          fixed: false,
        },
        {
          label: "KOL名称",
          prop: "kolName",
          sortable: false,
          align: "center",
          fixed: false,
        },
        {
          label: "平台",
          prop: "platform",
          sortable: false,
          width: 128,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.platformToZn(cellValue);
          },
        },
        {
          label: "发帖形式",
          prop: "postType",
          sortable: false,
          width: 80,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue === null ? "--" : this.postTypeToZn(cellValue);
          },
        },
        {
          label: "曝光量",
          prop: "realExposure",
          sortable: "custom",
          width: 100,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "互动量",
          prop: "realEngagement",
          sortable: "custom",
          width: 100,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "水量%",
          prop: "fake",
          sortable: "custom",
          width: 90,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue == null ? "--" : cellValue + "%";
          },
        },
        {
          label: "ER",
          prop: "engagementRate",
          sortable: "custom",
          width: 85,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue == null ? "--" : cellValue + "%";
          },
        },
        {
          label: "CPE",
          prop: "cpe",
          sortable: "custom",
          width: 96,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue == null ? "--" : cellValue;
          },
        },
        {
          label: "CPUV",
          prop: "cpi",
          sortable: "custom",
          width: 90,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue == null ? "--" : cellValue;
          },
        },
        {
          label: "CPTA",
          prop: "cpta",
          sortable: "custom",
          width: 88,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue == null ? "--" : cellValue;
          },
        },
      ],
      tableData: [],
      page: 1,
      pageSize: 10,
      totalSize: 100,
      loading: false,
      rolePlatform: [],
      tablePlatform: "all",
      tempSites: [],
      finSites: [],
      isExpand: false,
    };
  },
  props: {
    crowd: {
      type: String,
      default: "32_1",
    },
    campaignId: {
      type: Number,
      default: 0,
    },
    platform: {
      type: String,
      default: "all",
    },
    sites: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(["month"]),
    params() {
      return {
        ta: this.crowd,
        mon: this.month,
        campaignId: this.campaignId,
        platform: this.tablePlatform,
        sortColumn: this.sortCol,
        sortDirection: this.sortDirection,
        searchColumn: this.searchCol,
        searchWord: this.search,
        page: this.page,
        size: this.pageSize,
      };
    },
    expandFunc() {
      if (!this.isExpand) {
        return [];
      } else {
        return this.tableData.map((v) => {
          return v.contentId;
        });
      }
    },
  },
  methods: {
    initTableList() {
      this.searchColumn = "title";
      this.searchWord = "";
      // 调用一次排序事件
      this.$refs.sortTable.sort("postTime", "descending");
    },
    async getTableList() {
      this.loading = true;
      if (this.month) {
        const {
          data: { links, total },
        } = await getStoredList(this.params);
        this.tableData = links;
        this.totalSize = total;
        this.loading = false;
      }
    },
    handleSort({ column, prop, order }) {
      this.sortCol = prop;
      this.sortDirection = order ? order.slice(0, -6) : "";
      this.page = 1;
      this.getTableList();
    },
    handlePage(page) {
      this.page = page;
      this.getTableList();
    },
    handleSearchCol() {
      this.search = "";
      this.page = 1;
      this.getTableList();
    },
    handleSearch() {
      this.page = 1;
      this.getTableList();
    },
    buildUserUploadInfo(data) {
      let tempArr = [];
      tempArr.push({
        flowCost: data.flowCost,
        roi: data.roi,
        report: data.report,
      });
      return tempArr;
    },
    handleExpansion() {
      this.isExpand = !this.isExpand;
    },
  },
  watch: {
    tablePlatform() {
      this.getTableList();
    },
    month(val) {
      if (val) {
        this.getTableList();
      }
    },
    crowd() {
      this.initTableList();
    },
    platform() {
      this.initTableList();
    },
    sites: {
      handler(val) {
        this.tempSites = JSON.parse(JSON.stringify(val));
        this.tempSites.unshift({
          label: "全平台",
          value: "all",
        });
        this.finSites = this.tempSites;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.report_stored_table_class {
  .a_href {
    text-decoration: underline;
    color: #409eff;
    display: inline-block;
    width: 200px;
  }

  .spandAll {
    background-color: #f4f3f3;
    color: #8c98a5;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;

    .rotate {
      transform: rotate(-90deg);
    }
  }

  .isExpandClass {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    color: #fff;
  }
}
</style>

<style scoped lang="scss">
.spandAll {
  background-color: #f4f3f3;
  color: #8c98a5;
  border-radius: 4px;
  transition: transform 0.2s ease-in-out;

  .rotate {
    transform: rotate(-90deg);
  }
}

// .isExpandClass {
//   background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
//   color: #fff;
// }
</style>
